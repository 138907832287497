import React, { useState, useEffect, useRef } from 'react';
import ReactGA from 'react-ga4';
import { Bell, Book, BarChart, Globe, Languages, Rss, Search } from 'lucide-react';
import './LandingPage.css';
import heroBackground from './.\\images\\hero-background.jpg';
import computerScreen from './.\\images\\computer-screen.png';
import PricingSection from './PricingSection';
import AIAnalysisSection from './AIAnalysisSection';
import APISection from './APISection';
import { ReactComponent as Logo } from './filingreader-logo.svg';
import { Link } from 'react-router-dom';

const LandingPage = () => {
  const [scrollY, setScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const searchRef = useRef(null);

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: "/home" });
    const handleScroll = () => setScrollY(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (searchQuery.length > 2) {
      setIsLoading(true);
      setIsDropdownOpen(true);
      // Track search query
      ReactGA.event({
        category: 'Search',
        action: 'Performed Search',
        label: searchQuery
      });
      // Update this URL to match your backend server address
      fetch(`https://us-central1-filingreader-landing-page.cloudfunctions.net/api/search?query=${encodeURIComponent(searchQuery)}`)
        .then(response => response.json())
        .then(data => {
          setSearchResults(data);
          setIsLoading(false);
          // Track search results count
          ReactGA.event({
            category: 'Search',
            action: 'Received Results',
            value: data.length
          });
        })
        .catch(error => {
          console.error('Error fetching search results:', error);
          setIsLoading(false);
          // Track search error
          ReactGA.event({
            category: 'Search',
            action: 'Search Error',
            label: error.message
          });
        });
    } else {
      setSearchResults([]);
      setIsDropdownOpen(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchFocus = () => {
    if (searchResults.length > 0) {
      setIsDropdownOpen(true);
      // Track dropdown open
      ReactGA.event({
        category: 'Search',
        action: 'Opened Dropdown'
      });
    }
  };

  const handleCompanyClick = (company) => {
    const stockCode = company.stockCodes.split(',')[0].trim(); // Get the first stock code
    const exchangeCode = stockCode.split(':')[0];
    const ticker = stockCode.split(':')[1];
    const encodedCompanyName = encodeURIComponent(company.longNameEn);
    const url = `https://app.filingreader.com/?feed=${exchangeCode}%3A${ticker}&company=${encodedCompanyName}`;
    // Track company selection
    ReactGA.event({
      category: 'Search',
      action: 'Selected Company',
      label: company.longNameEn
    });
    window.open(url, '_blank');
  };

  const handleSignUpClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Sign Up Button',
    });
  };

  const features = [
    { 
      icon: <Globe className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'Global Coverage', 
      description: 'Access filings from stock exchanges worldwide, including major markets like SSE, HKEX, TSE, and emerging markets across Asia.' 
    },
    { 
      icon: <Bell className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'Real-time Alerts', 
      description: 'Stay ahead of the curve with instant notifications about new filings, price-sensitive information, and critical market updates tailored to your portfolio.' 
    },
    { 
      icon: <BarChart className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'AI-powered Analytics', 
      description: 'Leverage cutting-edge AI to analyze filings, extract key insights, and identify trends across companies and sectors.' 
    },
    { 
      icon: <Book className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'Embeddable Widgets', 
      description: "Integrate FilingReader's data directly into your website or internal tools with our customizable widgets, providing up-to-date filing information for multiple companies." 
    },
    { 
      icon: <Languages className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'Translate Filings', 
      description: 'Break language barriers with our advanced translation feature, allowing you to read filings from international markets in your preferred language.' 
    },
    { 
      icon: <Rss className="w-8 h-8 mb-4 text-secondary" />, 
      title: 'Industry Feeds', 
      description: 'Stay informed with curated industry-specific feeds, providing real-time updates on sector trends, regulatory changes, and key market movements.' 
    },
  ];

  return (
    <div className="font-sans text-gray-100 min-h-screen">
      <header className={`fixed w-full z-50 transition-all duration-300 ${scrollY > 50 ? 'bg-primary shadow-lg' : ''}`}>
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <a href="#" className="flex items-center">
              <Logo className="h-10 w-auto mr-2" />
            </a>
            <div className="hidden md:flex space-x-4">
              <a href="#features" className="hover:text-secondary">Features</a>
              <a href="#pricing" className="hover:text-secondary">Pricing</a>
              <Link to="/about" className="hover:text-secondary">About</Link>
              <a href="#" className="hover:text-secondary">Contact</a>
            </div>
            <a href="https://app.filingreader.com/?feed=all&result=signup" target="_blank" rel="noopener noreferrer" onClick={handleSignUpClick} className="bg-secondary text-white px-4 py-2 rounded-lg hover:bg-opacity-80 transition-all">Sign Up</a>
          </div>
        </nav>
      </header>
  
        <main>
        <section 
          className="hero relative min-h-[40vh] flex items-center pt-20 pb-20 overflow-visible"
          style={{
            backgroundImage: `url(${heroBackground})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center'
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>
          <div className="container mx-auto px-4 z-10">
            <div className="flex flex-col md:flex-row items-center justify-center gap-8">
              <div className="w-full md:w-1/2 max-w-xl mt-16 relative z-30"> 
                <h1 className="text-4xl font-bold mb-4 animate-fadeIn">Global stock exchange filings in one place</h1>
                <p className="text-lg mb-6 animate-fadeIn animation-delay-200">Unlock global financial intelligence with our easy-to-use platform: Access, analyze, and act on securities filings from major stock exchanges worldwide in real-time.</p>
                <div ref={searchRef} className="relative animate-fadeIn animation-delay-400">
            <input
              type="text"
              placeholder="Search companies..."
              className="w-full py-3 px-6 pr-12 rounded-lg bg-white text-gray-800 focus:outline-none focus:ring-2 focus:ring-secondary"
              value={searchQuery}
              onChange={handleSearchChange}
              onFocus={handleSearchFocus}
            />
            <Search className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            {isLoading && (
              <div className="absolute right-12 top-1/2 transform -translate-y-1/2">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-secondary"></div>
              </div>
            )}
            {isDropdownOpen && searchResults.length > 0 && (
              <ul className="absolute z-10 w-full bg-gray-800 mt-1 rounded-lg shadow-lg max-h-60 overflow-y-auto border border-gray-700 custom-scrollbar">
                {searchResults.map((result) => (
                  <li 
                    key={result.id} 
                    className="px-4 py-3 hover:bg-gray-700 cursor-pointer transition-colors duration-150 ease-in-out border-b border-gray-700 last:border-b-0"
                    onClick={() => handleCompanyClick(result)}
                  >
                    <div className="flex items-center">
                      <div className="w-8 h-8 mr-3 flex items-center justify-center">
                        {result.flag_url ? (
                          <img src={result.flag_url} alt="Country flag" className="w-6 h-6" />
                        ) : (
                          <div className="w-6 h-6 bg-secondary rounded-full flex items-center justify-center">
                            <span className="text-white font-semibold text-xs">
                              {result.longNameEn.charAt(0)}
                            </span>
                          </div>
                        )}
                      </div>
                      <div>
                        <p className="text-white font-semibold">{result.longNameEn}</p>
                        <p className="text-gray-400 text-sm">{result.stockCodes}</p>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>
              </div>
                <div className="w-full md:w-1/2 max-w-xl animate-fadeIn animation-delay-600 relative md:pt-28 md:-mb-52 z-20">
                  <div className="absolute inset-0" style={{ top: '22%', left: '11%', right: '11%', bottom: '20%' }}>
                    <iframe
                      src="/Evergrande_report.pdf"
                      title="Sample Filing PDF"
                      className="w-full h-full"
                    />
                  </div>
                  <img 
                    src={computerScreen} 
                    alt="FilingReader Dashboard" 
                    className="w-full h-auto object-contain relative z-10"
                  />
                </div>
              </div>
            </div>
          </section>
            
          <section id="features" className="bg-gray-900 py-20">
            <div className="container mx-auto px-4">
              <h2 className="text-3xl font-bold text-center mb-12">Our Features</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {features.map((feature, index) => (
                  <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg animate-slideIn" style={{animationDelay: `${index * 100}ms`}}>
                    {feature.icon}
                    <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
          <AIAnalysisSection />
          <APISection />
          <PricingSection />
        </main>

        <footer className="bg-gray-800 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap justify-between items-center">
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <a href="#" className="flex items-center mb-2">
                <Logo className="h-10 w-auto mr-2" />
              </a>
              <p className="text-gray-400">Empowering financial decisions with comprehensive global filing access.</p>
            </div>
            <div className="w-full md:w-1/3 mb-6 md:mb-0">
              <h4 className="text-lg font-semibold mb-2">Quick Links</h4>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-secondary">Home</a></li>
                <li><a href="#features" className="text-gray-400 hover:text-secondary">Features</a></li>
                <li><a href="#pricing" className="text-gray-400 hover:text-secondary">Pricing</a></li>
                <li><a href="#" className="text-gray-400 hover:text-secondary">Contact</a></li>
              </ul>
            </div>
            <div className="w-full md:w-1/3">
              <h4 className="text-lg font-semibold mb-2">Stay Connected</h4>
              <p className="text-gray-400 mb-2">Subscribe to our newsletter for updates.</p>
              <form className="flex">
                <input type="email" placeholder="Your email" className="bg-gray-700 text-white px-4 py-2 rounded-l-lg focus:outline-none" />
                <button type="submit" className="bg-secondary text-white px-4 py-2 rounded-r-lg hover:bg-opacity-80 transition-all">Subscribe</button>
              </form>
            </div>
          </div>
          <div className="mt-8 text-center text-gray-400">
            <p>&copy; 2024 FilingReader. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;