import React from 'react';
import { Globe, FileText, Clock, Users } from 'lucide-react';
import { ReactComponent as Logo } from './filingreader-logo.svg';
import { Link } from 'react-router-dom';

const AboutPage = () => {
  const stats = [
    { icon: <Globe className="w-8 h-8 text-secondary" />, value: '8+', label: 'Stock Exchanges' },
    { icon: <FileText className="w-8 h-8 text-secondary" />, value: '1M+', label: 'Filings Processed' },
    { icon: <Clock className="w-8 h-8 text-secondary" />, value: '24/7', label: 'Real-time Updates' },
    { icon: <Users className="w-8 h-8 text-secondary" />, value: '10k+', label: 'Active Users' },
  ];

  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <header className="bg-primary shadow-lg">
        <nav className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <a href="/" className="flex items-center">
              <Logo className="h-10 w-auto mr-2" />
            </a>
            <Link to="/" className="text-secondary hover:text-white transition-colors">Back to Home</Link>
          </div>
        </nav>
      </header>

      <main className="container mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-center">About FilingReader</h1>
        
        <section className="mb-12">
          <p className="text-lg mb-4">
            FilingReader aggregates and organizes stock exchange filings from around the world, making it easier to access key financial information. The platform provides timely, accurate, and comprehensive access to financial reports, annual statements, prospectuses, and other regulatory filings.
          </p>
          <p className="text-lg mb-4">
            Launched in 2021, FilingReader began providing data on Chinese stock exchanges, but has since expanded to eight exchanges primarily in the Asia-Pacific region.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Our Mission</h2>
          <p className="text-lg">
            At FilingReader, our mission is to democratize access to global financial information. We believe that by providing easy access to comprehensive and up-to-date financial filings, we can empower investors, analysts, and financial professionals to make more informed decisions.
          </p>
        </section>

        <section className="mb-12">
          <h2 className="text-3xl font-semibold mb-6">Key Features</h2>
          <ul className="list-disc list-inside text-lg space-y-2">
            <li>Real-time access to filings from major Asian stock exchanges</li>
            <li>Advanced search and filtering capabilities</li>
            <li>AI-powered analysis and insights</li>
            <li>Customizable alerts and notifications</li>
            <li>Multi-language support for global users</li>
          </ul>
        </section>

        <section className="grid grid-cols-2 md:grid-cols-4 gap-8 mb-12">
          {stats.map((stat, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg shadow-lg text-center">
              {stat.icon}
              <p className="text-3xl font-bold mt-2">{stat.value}</p>
              <p className="text-gray-400">{stat.label}</p>
            </div>
          ))}
        </section>

        <section>
          <h2 className="text-3xl font-semibold mb-6">Contact Us</h2>
          <p className="text-lg mb-4">
            Have questions or need support? We're here to help!
          </p>
          <a href="mailto:support@filingreader.com" className="bg-secondary text-white px-6 py-3 rounded-lg hover:bg-opacity-80 transition-all inline-block">
            Contact Support
          </a>
        </section>
      </main>

      <footer className="bg-gray-800 py-8 mt-12">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 FilingReader. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default AboutPage;