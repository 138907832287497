import React, { useState, useEffect } from 'react';
import { FileText, BarChart } from 'lucide-react';
import BankOfChinaCharts from './BankOfChinaCharts';

const AIAnalysisSection = () => {
  const [analysisText, setAnalysisText] = useState('');
  const [showCharts, setShowCharts] = useState(false);
  const fullText = `This is an AI-generated analysis of the 2024 semi-annual financial statements and related notes for Bank of China Limited. Key points include:

• As of June 30, 2024, Bank of China's total assets were 33.91 trillion yuan, up 4.55% from the end of 2023.

• Net profit attributable to shareholders increased by 3.2% year-on-year to 112.43 billion yuan.

• The non-performing loan ratio decreased by 0.02 percentage points to 1.29% compared to the end of the previous year.

• The bank's capital adequacy ratio stood at 16.41%, maintaining a strong capital position.

• Overseas operations contributed 16.8% of the group's pre-tax profit, showing continued international growth.

This analysis suggests that Bank of China has maintained steady growth and improved asset quality in the first half of 2024, despite global economic challenges.`;

  useEffect(() => {
    let index = 0;
    const textIntervalId = setInterval(() => {
      if (index < fullText.length) {
        setAnalysisText((prev) => prev + fullText[index]);
        index++;
      } else {
        clearInterval(textIntervalId);
      }
    }, 25);

    return () => clearInterval(textIntervalId);
  }, []);

  const formatText = (text) => {
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line.startsWith('•') ? (
          <li className="ml-4">{line.substr(1).trim()}</li>
        ) : (
          <p className="mb-2">{line}</p>
        )}
      </React.Fragment>
    ));
  };

  const toggleView = () => {
    setShowCharts((prev) => !prev);
  };

  return (
    <section id="ai-analysis" className="bg-gray-900 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-white">AI-Powered Filing Analysis</h2>
        <div className="flex flex-col md:flex-row gap-8">
          <div className="w-full md:w-1/2 bg-gray-800 p-4 rounded-lg shadow-lg">
            <div className="aspect-w-16 aspect-h-9" style={{ height: '600px' }}>
              <iframe
                src="/Bank_of_China_semi_annual_report.pdf"
                title="Sample PDF Viewer"
                className="w-full h-full rounded-lg"
              />
            </div>
          </div>
          <div className="w-full md:w-1/2 bg-gray-800 p-4 rounded-lg shadow-lg">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                {showCharts ? (
                  <>
                    <BarChart className="w-6 h-6 mr-2 text-secondary" />
                    <h3 className="text-xl font-semibold text-white">Data Analyst: Visual Insights</h3>
                  </>
                ) : (
                  <>
                    <FileText className="w-6 h-6 mr-2 text-secondary" />
                    <h3 className="text-xl font-semibold text-white">Writer: Summary Analysis</h3>
                  </>
                )}
              </div>
              <button
                onClick={toggleView}
                className="bg-secondary text-white px-4 py-2 rounded-lg hover:bg-opacity-80 transition-all"
              >
                {showCharts ? 'Show Summary' : 'Show Charts'}
              </button>
            </div>
            <div className="bg-gray-700 p-4 rounded-lg h-[600px] overflow-y-auto">
              {showCharts ? (
                <BankOfChinaCharts />
              ) : (
                <ul className="text-gray-300 font-mono space-y-2 list-disc pl-5">
                  {formatText(analysisText)}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AIAnalysisSection;