import React from 'react';
import { BarChart, Bar, XAxis, YAxis, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, Legend } from 'recharts';

const BankOfChinaCharts = () => {
  const assetsData = [
    { year: '2023 Year-End', assets: 32.43 },
    { year: '2024 Mid-Year', assets: 33.91 },
  ];

  const profitData = [
    { year: '2023 H1', profit: 127.69 },
    { year: '2024 H1', profit: 126.54 },
  ];

  const ratiosData = [
    { ratio: 'Non-performing Loan Ratio', '2023 Year-End': 1.27, '2024 Mid-Year': 1.24 },
    { ratio: 'Capital Adequacy Ratio', '2023 Year-End': 17.74, '2024 Mid-Year': 18.91 },
  ];

  return (
    <div className="space-y-8">
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-xl font-semibold mb-4 text-white">Total Assets Growth</h3>
        <BarChart width={600} height={300} data={assetsData}>
          <XAxis dataKey="year" stroke="#fff" />
          <YAxis stroke="#fff" />
          <Bar dataKey="assets" fill="#8884d8" />
        </BarChart>
      </div>

      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-xl font-semibold mb-4 text-white">Net Profit Comparison</h3>
        <BarChart width={600} height={300} data={profitData}>
          <XAxis dataKey="year" stroke="#fff" />
          <YAxis stroke="#fff" />
          <Bar dataKey="profit" fill="#82ca9d" />
        </BarChart>
      </div>

      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-xl font-semibold mb-4 text-white">Key Ratios Comparison</h3>
        <RadarChart width={600} height={300} data={ratiosData}>
          <PolarGrid stroke="#fff" />
          <PolarAngleAxis dataKey="ratio" stroke="#fff" />
          <PolarRadiusAxis angle={30} domain={[0, 20]} stroke="#fff" />
          <Radar name="2023 Year-End" dataKey="2023 Year-End" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
          <Radar name="2024 Mid-Year" dataKey="2024 Mid-Year" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.6} />
          <Legend />
        </RadarChart>
      </div>
    </div>
  );
};

export default BankOfChinaCharts;