import React, { useState } from 'react';
import { Check } from 'lucide-react';

const PricingSection = () => {
  const [isMonthly, setIsMonthly] = useState(true);

  const PricingCard = ({ title, description, price, features, isPopular }) => (
    <div className={`bg-gray-800 p-6 rounded-lg shadow-lg ${isPopular ? 'border-2 border-secondary' : ''}`}>
      <h3 className="text-2xl font-bold mb-2">{title}</h3>
      <p className="text-gray-400 mb-4">{description}</p>
      <p className="text-3xl font-bold mb-6">
        {isMonthly ? (
          <>${price}<span className="text-lg font-normal">/Mo</span></>
        ) : (
          <>${price * 10}<span className="text-lg font-normal">/Yr</span></>
        )}
      </p>
      <ul className="space-y-2 mb-6">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <Check className="w-5 h-5 mr-2 text-secondary" />
            <span>{feature}</span>
          </li>
        ))}
      </ul>
      <a
        href="https://app.filingreader.com/?page=plan"
        target="_blank"
        rel="noopener noreferrer"
        className={`w-full py-2 px-4 rounded-lg transition-all inline-block text-center ${
          isPopular ? 'bg-secondary hover:bg-opacity-80' : 'bg-gray-700 hover:bg-gray-600'
        }`}
      >
        Choose plan
      </a>
    </div>
  );

  return (
    <section id="pricing" className="bg-gray-900 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Pricing Plans</h2>
        <div className="flex justify-center mb-8">
          <div className="bg-gray-800 p-1 rounded-full">
            <button
              className={`px-4 py-2 rounded-full ${isMonthly ? 'bg-secondary' : ''}`}
              onClick={() => setIsMonthly(true)}
            >
              Monthly
            </button>
            <button
              className={`px-4 py-2 rounded-full ${!isMonthly ? 'bg-secondary' : ''}`}
              onClick={() => setIsMonthly(false)}
            >
              Annual
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <PricingCard
            title="FREE"
            description="For individual research projects"
            price={0}
            features={[
              "Access 2 stock exchanges",
              "Track 3 companies",
              "Weekly e-mail notifications"
            ]}
          />
          <PricingCard
            title="PRO"
            description="For regular research and/or tracking multiple companies"
            price={16}
            features={[
              "Access a minimum of 5 exchanges",
              "Translate unlimited filings",
              "Track unlimited companies",
              "Create personalized feeds",
              "Advanced search",
              "Realtime, hourly, daily and weekly e-mail notifications",
              "Create RSS feeds"
            ]}
            isPopular={true}
          />
          <PricingCard
            title="TEAM"
            description="For organizations that want multiple user accounts"
            price={24}
            features={[
              "All the benefits of Pro plus:",
              "Multiple user accounts",
              "Create team feeds",
              "Share saved filings, translations and feeds within your team",
              "Lower cost per user"
            ]}
          />
        </div>
      </div>
    </section>
  );
};

export default PricingSection;