import React from 'react';
import { Code, Database, Key } from 'lucide-react';

const APISection = () => {
  return (
    <section id="api" className="bg-gray-800 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12 text-white">Power Your Applications with Our API</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <h3 className="text-2xl font-semibold text-secondary">Access Over 10 Million Company Filings</h3>
            <p className="text-gray-300">
              Integrate FilingReader's extensive database directly into your applications, 
              tools, or workflows. Our RESTful API provides programmatic access to our 
              entire collection of global company filings.
            </p>
            <ul className="space-y-4">
              {[
                { icon: <Database className="w-6 h-6" />, text: "Comprehensive coverage of global stock exchanges" },
                { icon: <Code className="w-6 h-6" />, text: "Easy-to-use RESTful API with detailed documentation" },
                { icon: <Key className="w-6 h-6" />, text: "Secure authentication and flexible rate limits" }
              ].map((item, index) => (
                <li key={index} className="flex items-center space-x-3 text-gray-300">
                  <span className="text-secondary">{item.icon}</span>
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
            <a href="#" className="inline-block bg-secondary text-white font-bold py-3 px-6 rounded-lg hover:bg-opacity-80 transition-all">
              Explore API Documentation
            </a>
          </div>
          <div className="bg-gray-700 p-6 rounded-lg shadow-lg">
            <pre className="text-sm text-gray-300 overflow-x-auto">
              <code>{`
# Python example using requests library
import requests

API_KEY = 'your_api_key_here'
BASE_URL = 'https://api.filingreader.com/v1'

# Get the latest 10-K filing for Apple Inc.
response = requests.get(
    f'{BASE_URL}/filings',
    params={
        'company': 'AAPL',
        'type': '10-K',
        'limit': 1
    },
    headers={'Authorization': f'Bearer {API_KEY}'}
)

if response.status_code == 200:
    filing = response.json()['data'][0]
    print(f"Latest 10-K for Apple Inc:")
    print(f"Filed on: {filing['date']}")
    print(f"Download URL: {filing['url']}")
else:
    print(f"Error: {response.status_code}")
    print(response.json())
              `}</code>
            </pre>
          </div>
        </div>
      </div>
    </section>
  );
};

export default APISection;